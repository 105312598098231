.ant-layout {
  background: #f5f5f5;
}

.sticky-footer {
  position: sticky;
  left: 0;
  padding-left: 28px;
  bottom: 10vh;
  // height: 60px;
  width: 100%;
  z-index: 999;
}
