.expression {
  :global {
    .ant-space-item:first-child {
      width: 100%;
    }
  }
}

.rangePickerButton {
  width: 100%;
}

.scaffoldTab {
  width: 700px;
  margin: 0 auto;

  :global {
    .ant-tabs-nav {
      margin-bottom: 0;

      &::before {
        border-bottom: 0;
      }
    }

    .ant-tabs-content-holder {
      width: 700px;
      height: 498px;
      padding: 40px;
      background: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
  }
}
