.loginModal {
  :global {
    .ant-modal-header {
      display: flex;
      justify-content: center;
    }
  }
}

@media screen and (max-width: 1600px) {
  .responsiveLargeFont {
    font-size: 64px;
  }
}

@media screen and (min-width: 1600px) {
  .responsiveLargeFont {
    font-size: 100px;
  }
}
